// FONTS
$font-family-cursive: "Abril Fatface", cursive;
$font-family: "houschka-pro", sans-serif;

$font-size-smaller: 14px;
$font-size-normal: 16px;
$font-size-larger: 18px;
$font-size-x-large: 24px;
$font-size-xx-large: 28px;
$font-size-largest: 36px;

$font-color: #bed5f7e3;

$font-bold: 700;

// COLORS
$color-light: #121324;
$color-dark: #09091a;
$color-white: #fff;
$color-active: #00f2ff;
$color-primary: #000000;
