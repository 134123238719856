$font-awesome: "Font Awesome 5 Free";

/*=================================================================*/
/*                      ELEMENTS
/*=================================================================*/
/* === Timeline === */
.timeline {
  position: relative;

  .timeline-container {
    padding-left: 50px;
    margin-bottom: 50px;
    position: relative;
    background-color: inherit;
    width: 100%;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .content {
    position: relative;

    .time {
      color: #8b88b1;
      font-size: $font-size-smaller;
    }

    h3 {
      font-size: 20px;
      margin: 10px 0;
    }

    p {
      margin: 0;
    }

    a {
      color: #00f2ff;
      &:hover {
        color: #fff;
      }
    }
    i {
      display: inline-block;
      height: 1rem;
      width: 1rem;
    }
  }

  span {
    &.line {
      position: absolute;
      width: 1px;
      background-color: $color-primary;
      top: 30px;
      bottom: 30px;
      left: 34px;
    }
  }
}

/* === Social Icons === */
.social-icons {
  li:not(:last-child) {
    margin-right: 1.5rem;
  }

  li {
    a {
      font-size: 21px;
      svg {
        &:hover {
          color: #25d2db;
        }
      }
    }
  }
}

/* === Helper === */
.rounded {
  border-radius: 20px !important;
}

.bg-white {
  background: $color-dark !important;
}

.shadow-dark,
.kd-header .dropdown-menu,
.form-control,
.form-control:focus {
  box-shadow: rgb(136 153 166 / 20%) 0px 0px 15px,
    rgb(136 153 166 / 15%) 0px 0px 3px 1px;
}

.shadow-light {
  box-shadow: 0px 5px 20px 0px rgba(255, 255, 255, 0.01);
}

.shadow-blue {
  box-shadow: 0px 5px 20px 0px rgba(108, 108, 229, 0.5);
}

.shadow-pink {
  box-shadow: 0px 5px 20px 0px rgba(249, 123, 139, 0.5);
}

.shadow-yellow {
  box-shadow: 0px 5px 20px 0px rgba(249, 215, 76, 0.5);
}

.padding-30 {
  padding: 30px;
}

.triangle-left {
  &:before {
    content: "";
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 15px solid $color-white;
    position: absolute;
    left: -15px;
    top: 20%;
  }
}

.triangle-top {
  &:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $color-white;
    position: absolute;
    left: 50%;
    top: -10px;
    transform: translateX(-7.5px);
  }
}

/*=================================================================*/
/*                      FACTS                              
/*=================================================================*/
.fact-item {
  .details {
    margin-left: 60px;
  }

  .icon {
    font-size: $font-size-largest;
    color: #dedeea;
    float: left;
  }

  .number {
    font-size: 30px;

    em {
      font-style: normal;
    }
  }

  p {
    font-size: $font-size-normal;
  }
}

/* === Go to Top === */
#return-to-top {
  position: fixed;
  bottom: 25px;
  right: 25px;
  background: rgba(0, 0, 0, 0.2);
  width: 40px;
  height: 40px;
  display: block;
  text-decoration: none;
  border-radius: 100%;
  display: none;
  z-index: 4;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  svg {
    color: $color-white;
    margin: 0;
    position: relative;
    left: 13px;
    top: 8px;
    font-size: 16px;
    transform: translateY(0px);
    transition: all 0.1s ease-in-out;
  }

  &:hover {
    background: $color-primary;
  }
}
